import { chakra, Container, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { Hx } from "src/components/Heading/Heading";
import { Carousel, CarouselItem } from "../Carousel/Carousel";
import { ContactPhoto } from "../Contact/Contact";
import { DatoAssetData } from "../Dato/DatoAsset";

export type CustomerReviewSectionProps = {
	title: string;
	reviews: Array<{
		id: string;
		name: string;
		role: string | null;
		quote: string;
		photo: DatoAssetData;
	}>;
};

export const CustomerReviewSection: React.FC<CustomerReviewSectionProps> = ({
	title,
	reviews,
}) => {
	const styles = useMultiStyleConfig("CustomerReviewSection");

	if (reviews.length === 0) {
		return null;
	}

	return (
		<chakra.div sx={styles.wrapper}>
			<Container maxW="container.xl">
				<Hx sx={styles.title}>{title}</Hx>
			</Container>
			<chakra.div __css={styles.carouselWrapper}>
				<Carousel loop={true} loopedSlides={6}>
					{reviews.map(({ id, name, role, quote, photo }) => (
						<CarouselItem key={id}>
							<chakra.figure sx={styles.content}>
								<chakra.figcaption sx={styles.details}>
									<ContactPhoto
										name={name}
										photo={photo}
										sx={styles.image}
									/>
									<chakra.div>
										<Hx size={{ base: "h5", md: "h4" }}>
											{name}
										</Hx>
										<p>{role}</p>
									</chakra.div>
								</chakra.figcaption>
								<chakra.blockquote sx={styles.quote}>
									{quote}
								</chakra.blockquote>
							</chakra.figure>
						</CarouselItem>
					))}
				</Carousel>
			</chakra.div>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
